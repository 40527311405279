import React from "react";
import { useNavigate } from "react-router-dom";
import "./changelog.css";

const Changelog = () => {
  const navigate = useNavigate();
  const changelogData = [
    {
      version: "v1.1.9",
      changes: "Minor Chat Mode Fixes",
    },
    {
      version: "v1.1.8",
      changes: "Minor Fixes and Improve Chat Mode Performance",
    },
    {
      version: "v1.1.7",
      changes: "Reasoning & Markdown Support added to Chat Mode.",
    },
    {
      version: "v1.1.6",
      changes: "Event streaming support added for chat mode.",
    },
    {
      version: "v1.1.5",
      changes:
        "Moved chat feature to sidePanel, Added Support for File Context",
    },
    {
      version: "v1.1.4",
      changes: "Minor change",
    },
    {
      version: "v1.1.3",
      changes: "New UI, Select Code to Chat Feature added",
    },
    {
      version: "v1.1.2",
      changes: "Minor Fixes",
    },
    {
      version: "v1.1.1",
      changes:
        "Multiple Languages Support, html support for Code AutoComplete and Chat bug fixed",
    },
    {
      version: "v1.1.0",
      changes: "Code AutoComplete",
    },
    {
      version: "v1.0.9",
      changes: "Comment Question Based Inline Suggestions",
    },
    {
      version: "v1.0.8",
      changes: "Spotlight UI Updated and chat history bug fixed.",
    },
    {
      version: "v1.0.5",
      changes: "Retrieval augmented generation (RAG) added to Realtime Chat.",
    },
    {
      version: "v1.0.4",
      changes: "Token expiry issue fixed",
    },
    {
      version: "v1.0.3",
      changes:
        "Chat History Feature Added, UI updated and chat and spotlight icons not appearing on load (fixed)",
    },
    {
      version: "v1.0.2",
      changes: "Minor Bugs Fixes",
    },
    {
      version: "v1.0.1",
      changes: "Extension is totally updated",
    },
  ];

  return (
    <div className="changelog_page" id="changelog">
      <button
        onClick={() => navigate("/")}
        className="back-button px-6 py-3 mb-8 bg-gradient-to-r from-indigo-500 to-purple-600 text-white rounded-lg font-medium transition-all duration-300 hover:shadow-lg hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 flex items-center gap-2"
      >
        <span>←</span>
      </button>
      <div className="changelog_page_h1tag">
        Track Our Progress and Updates
      </div>
      <div className="changelog-container">
        {changelogData.map((item, index) => (
          <div key={index} className="changelog-item">
            <div className="version-badge">{item.version}</div>
            <div className="changelog-content">
              <p>{item.changes}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Changelog;
