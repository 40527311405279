import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="main_page_contact" id="footer">
      <div className="main_page_footer">
        <div className="main_page_footer_content">
          <p className="main_page_footer_poweredby">
            Powered by
            <a
              href="https://www.linkedin.com/company/bytelogist/"
              className="main_page_footer_company_link"
            >Bytelogist</a>
          </p>
          <div className="main_page_footer_social">
            <a
              href="https://twitter.com/yourcompany"
              className="main_page_footer_link"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/google-apps-script-copilot/"
              className="main_page_footer_link"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
          <div className="main_page_footer_links">
            <Link to="./privacypolicy" className="main_page_footer_link">
              Privacy Policy
            </Link>
            <Link to="./termsofservice" className="main_page_footer_link">
              Terms of Service
            </Link>
            <Link to="./changelog" className="main_page_footer_link">
              Changelog
            </Link>
          </div>
          <p className="main_page_footer_text">
            &copy; 2023 GS Copilot. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
