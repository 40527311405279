import React from "react";
import intro_video from "../assets/videos/gs copilot intro.mp4";

function Home() {
  return (
    <div className="main_page_home" id="home">
      <div className="main_page_home_text">
        <h1 className="main_page_home_titletext">
          Unleash Your Scripting Potential With GS Copilot.
        </h1>
        <a
          className="main_page_home_linkdiv"
          rel="noreferrer"
          target="_blank"
          href="https://chromewebstore.google.com/detail/google-apps-script-copilo/aakmllddlcknkbcgjabmcgggfciofbgo"
        >
          Get Started
        </a>
      </div>
      <div className="main_page_home_imgdiv">
        <video
          className="main_page_home_image"
          autoPlay
          muted
          loop
          playsInline
          width="640"
          height="360"
          src={intro_video}
          alt="GS Copilot logo"
          controlsList="nodownload nofullscreen noremoteplayback nooptions"
        ></video>
      </div>
    </div>
  );
}

export default Home;
