import React, { useState, useEffect } from "react";
import image from "../assets/images/image.png";
import "./uninstall.css";
import Swal from "sweetalert2";

const Uninstall = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitText, setSubmitText] = useState("Submit Feedback");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("token")) {
      setIsSubmitted(true);
    } else {
      const tokenValue = urlParams.get("token");
      const newUrl = `https://gscopilot.com/uninstallstate?token=${tokenValue}`;
      console.log("Token found in the URL:", tokenValue);
      fetch(newUrl, { method: "GET", mode: "no-cors" })
        .then((response) => console.log("Token request sent successfully"))
        .catch((error) => console.error("Error sending token request:", error));
    }
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    setSubmitText("Submitting...");

    const reasonElement = document.getElementById("reason");
    const commentElement = document.getElementById("comments");

    const reasonValue = reasonElement.options[reasonElement.selectedIndex].text;
    const commentValue = commentElement.value;

    console.log("Reason:", reasonValue);
    console.log("Comments:", commentValue);

    const currentUrl = window.location.href;
    console.log("Current URL:", currentUrl);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("token")) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const userName = urlParams.get("email");

      const raw = JSON.stringify({
        action: "feedback",
        descriptionValue: commentValue,
        causeValue: reasonValue,
        userName: userName,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        mode: "no-cors",
      };

      fetch(
        "https://script.google.com/macros/s/AKfycbwYDWIQOUf0Wr-wLK2zVo6GSVcGb4GBP8wor6KtggWWA-vGa-5vFLUZW0vkZXHZzTlE/exec",
        requestOptions
      )
        .then((response) => {
          console.log("Request sent successfully");
          setSubmitText("Feedback Submitted");
          Swal.fire({
            title: "Thank you!",
            text: "Thank you for your feedback!",
            icon: "success",
          });
          commentElement.value = "";
          reasonElement.selectedIndex = 0;
          // alert('Thank you for your feedback! We appreciate your support.');
        })
        .catch((error) => console.error(error));
    } else {
      console.log("Token not found in the URL");
    }
    setIsSubmitted(true);
  };
  return (
    <div className="uninstall">
      <div className="container">
        <h1>We're sad to see you go!</h1>
        <img src={image} alt="Uninstall" />
        <p>
          We’d love to hear your feedback on why you are uninstalling the
          application. Help us improve our work by letting us know about any
          issue you've encountered
        </p>
        <form>
          <label htmlFor="reason">Reason for Uninstalling:</label>
          <select id="reason" name="reason">
            <option value="bug">App has too many bugs</option>
            <option value="features">Lack of features</option>
            <option value="performance">Performance issues</option>
            <option value="other">Other</option>
          </select>

          <label htmlFor="comments">Additional Comments:</label>
          <textarea
            id="comments"
            name="comments"
            rows="4"
            placeholder="Let us know how we can improve..."
          ></textarea>
          <div className="buttons">
            <button
              type="submit"
              className="submit-btn"
              onClick={handleClick}
              disabled={isSubmitted}
            >
              {submitText}
            </button>
            <button type="button" className="cancel-btn">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Uninstall;
